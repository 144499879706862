export default {
  PRODUCT_SELECTION: "PRODUCT_SELECTION",
  ROLES:"ROLES",
  MAILING:"MAILING",
  SERVICES:"SERVICES",
  CIP:"CIP",
  FUNDING:"FUNDING",
  ALL:"ALL",
  FINAL_DISCLOSURE:"FINAL_DISCLOSURE",
  SUMMARY:"SUMMARY",
  BRANCH_SELECTION:"BRANCH_SELECTION",
  END:"END",
  WOLTERS_KLUWER:"WOLTERS_KLUWER",
  RIGHT_OF_SURVIVORSHIP:"RIGHT_OF_SURVIVORSHIP",
  PERSON_RELATIONSHIP:"PERSON_RELATIONSHIP",
  VALIDATE_PLAID:"VALIDATE_PLAID",
  BUSINESS_RESOLUTION:"BUSINESS_RESOLUTION",
  BUSINESS_OWNERSHIP: "BUSINESS_OWNERSHIP",
  REFERENCES: "REFERENCES"
} as const;